const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6">
        <div className="who-we-contant">
            <div className="dream-dots">
            </div>
            <h4 data-aos="fade-up">Join our community</h4>
            <p data-aos="fade-up">Join our discord server for exclusive benefits such as giveaways, alpha, and more!</p>
            <a data-aos="fade-up" className="btn dream-btn mt-30" href="https://discord.com/invite/gray-experiment">Discord</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;