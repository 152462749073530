import CountUp from 'react-countup';

const SecWelcomeMeter = ({img}) => {

  return (
    <div className="col-12 col-lg-6" data-aos="fade-up">
        <div className="welcome-meter">
            <img style={{"paddingTop":"25px"}} src="wolf2.png" className="img-responsive center-block" alt="" />
            <div className="growing-company text-center mt-30">
                <p>* Unique holders <span className="counter"><CountUp start={0} end={10677} duration={20}/> </span> </p>
            </div>
        </div>
    </div>
  );
}

export default SecWelcomeMeter;