

const Li_A = ({nameIco}) => <li><a href="#"><i className={nameIco} aria-hidden="true"></i></a></li>

const SecVerticalSocial = ({data}) => {

  return (
    <div className="vertical-social">
      
        <ul>
          <li><a href="https://twitter.com/Gray_Experiment"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
          <li><a href="https://discord.gg/gray-experiment"><img width="22px" src="discord.svg" aria-hidden="true"></img></a></li>
          <li><a href="https://grayexperiment.gitbook.io/gray-experiment/"><i className="fa fa-book"  aria-hidden="true"></i></a></li>
          <li><a href="https://birdeye.so/token/6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG?chain=solana"><img width="22px" src="feather-solid.svg" aria-hidden="true"></img></a></li>      
        </ul>                  
    </div>
  );
}

export default SecVerticalSocial;