const Span = () => <span></span>

const SecWhoWeContant = () => {

  return (
    <div className="col-12 col-lg-6 mb-30">
        <div className="who-we-contant">
            <div className="dream-dots" data-aos="fade-up">
                {Array(7).fill().map((key) => (
                		<Span />
                	))}
            </div>
            <h4 data-aos="fade-up">How to buy $GRAY</h4>
            <p data-aos="fade-up">To purchase $GRAY you will need to get a phantom wallet, buy SOL and send to your phantom wallet. Then swap from SOL to $GRAY on Raydium or Jupiter.</p>
            <a className="btn dream-btn mt-30" style={{"marginRight":"10px"}} href="https://phantom.app/">Phantom Wallet</a>
            <a className="btn dream-btn mt-30" style={{"marginRight":"10px"}} href="https://jup.ag/swap/SOL-GRAY">Jupiter</a>
            <a className="btn dream-btn mt-30" href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6XfB1VVAxjtfKnF1kotGxLUq4p87xTDp3cCyC6Q2VHNG&fixed=out">Raydium</a>
        </div>
    </div>
  );
}

export default SecWhoWeContant;