function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          <div className="promo-section">
              <div className="integration-link">
                  <span className="integration-icon">
                      <img src={img} width="24" height="24" alt="" />
                  </span>
                  <span className="integration-text">Experimenting on Solana</span>
              </div>
          </div>
          <h1>Welcome to the $GRAY Experiment</h1>
          <p>
          $GRAY is an experimental token on the Solana blockchain. The name Gray comes from a Wolf. This is a community focused project backed by a team dedicated to having fun and trying new things on Solana.
          </p>
          <div className="dream-btn-group">
              <a href="https://twitter.com/Gray_Experiment" className="btn dream-btn mr-3">Follow Us!</a>
          </div>
      </div>
  )
}

export default SecWelcomeContent